<template>
  <div>
    <transition name="slide">
      <div v-if="isVisible" class="bg-gray pl-3 pr-3">
        <p class="header-text">
          Quase lá! Mais algumas informações e finalizamos sua assinatura.
          Rápido e Fácil!
        </p>
        <div class="content">
          <div class="row-plane">
            <p class="plan-text">
              Plano escolhido:
              {{
                translateType(
                  plane && plane.planeValue && plane.planeValue.type
                )
              }}
            </p>
            <div class="change-text">
              <p @click="backStep">Mudar</p>
            </div>
          </div>
          <div class="green-box">
            <p class="white-text">
              {{ plane && plane.planeValue && plane.planeValue.Title }}
              {{ comboData.planeValue ? "+ Seguro Acidente" : "" }}
            </p>
            <div class="white-box">
              <p class="gray-text">
                R${{
                  plane &&
                  plane.planeValue &&
                  convertToCents(plane.planeValue.price)
                }}
              </p>
              <p v-if="comboData.planeValue" class="gray-text">
                + R${{ convertToCents(comboData.planeValue.price) }}
              </p>

              <p v-if="comboData.planeValue" class="gray-text">
                = R${{
                  convertToCents(
                    comboData.planeValue.price + plane.planeValue.price
                  )
                }}
                /
                {{ plane.planeValue.type }}
              </p>
            </div>
          </div>
          <div class="tabs">
            <div class="tab" :class="{ selected: selectedTab === 1 }">
              Dados pessoais
            </div>
            <div class="tab" :class="{ selected: selectedTab === 2 }">
              Dados Bike
            </div>
            <div class="tab" :class="{ selected: selectedTab === 3 }">
              Pagamento
            </div>
          </div>
          <div class="tab-content">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
              <div v-if="selectedTab === 1" :key="'step-1'">
                <div class="row-social">
                  <b-row>
                    <svg
                      width="16px"
                      height="16px"
                      class="mr-2 ml-2"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75Z"
                          fill="#1C274C"
                        ></path>
                        <path
                          d="M12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z"
                          fill="#1C274C"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
                          fill="#1C274C"
                        ></path>
                      </g>
                    </svg>
                    <p @click="openDrawerSocial" class="social-name">
                      Deseja informar o nome social?
                    </p>
                  </b-row>
                  <span class="switch" @click="hasSocialName = !hasSocialName">
                    <input v-model="hasSocialName" type="checkbox" />
                    <span class="slider round"></span>
                  </span>
                </div>
                <div v-if="hasSocialName">
                  <ValidationProvider
                    name="Nome Social"
                    rules="required|nome_completo"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.social_name"
                      :model="fields.social_name"
                      :placeholder="'Nome Social'"
                      type="text"
                    />
                  </ValidationProvider>
                </div>
                <ValidationProvider
                  name="Nome Completo"
                  rules="required|nome_completo"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    :errors="errors"
                    v-model="fields.name"
                    :model="fields.name"
                    :placeholder="'Nome Completo'"
                    type="text"
                  />
                </ValidationProvider>

                <ValidationProvider
                  name="Nome Completo"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    v-model="fields.email"
                    :errors="errors"
                    :model="fields.email"
                    :placeholder="'Email'"
                    type="text"
                  />
                </ValidationProvider>

                <div class="row-plane">
                  <ValidationProvider
                    name="CPF"
                    rules="required|cpf"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.cpf"
                      :model="fields.cpf"
                      :placeholder="'CPF'"
                      :isSmall="true"
                      v-mask="'###.###.###-##'"
                      type="tel"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    name="RG"
                    rules="required|min:4"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.rg"
                      :model="fields.rg"
                      :placeholder="'RG'"
                      :isSmall="true"
                      type="tel"
                    />
                  </ValidationProvider>
                </div>
                <div class="row-plane" :class="!exibirCamposEndereco && 'mb-5'">
                  <ValidationProvider
                    name="CEP"
                    rules="required|min:9"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.cep"
                      :model="fields.cep"
                      :placeholder="'CEP'"
                      :isSmall="true"
                      v-mask="'#####-###'"
                      type="tel"
                    />
                  </ValidationProvider>
                  <div v-if="loadingCep" class="search-address">
                    Buscando endereço...
                  </div>
                  <ValidationProvider
                    v-if="exibirCamposEndereco && !loadingCep"
                    name="Cidade"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.city"
                      :model="fields.city"
                      :placeholder="'Cidade'"
                      :isSmall="true"
                      type="text"
                    />
                  </ValidationProvider>
                </div>
                <ValidationProvider
                  v-show="exibirCamposEndereco && !loadingCep"
                  name="Bairro"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    :errors="errors"
                    :placeholder="'Bairro'"
                    v-model="fields.hood"
                    :model="fields.hood"
                    type="text"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-show="exibirCamposEndereco && !loadingCep"
                  name="Endereço"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    v-model="fields.address"
                    :model="fields.address"
                    :placeholder="'Endereço'"
                    :errors="errors"
                    type="text"
                  />
                </ValidationProvider>
                <div
                  class="row-plane-last"
                  v-show="exibirCamposEndereco && !loadingCep"
                >
                  <div @click="openzada">
                    <CustomTextInput
                      :isDisabled="true"
                      :placeholder="placeholderUF"
                      type="tel"
                      :isDrawer="true"
                    />
                  </div>
                  <ValidationProvider
                    name="Número"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      v-model="fields.number"
                      :model="fields.number"
                      :placeholder="'Número'"
                      :errors="errors"
                      type="tel"
                    />
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="selectedTab === 2" :key="'step-2'">
                <div class="row-plane">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Marca"
                    mode="passive"
                  >
                    <CustomTextInput
                      :isDisabled="true"
                      :model="fields.brand"
                      :placeholder="'Marca'"
                      v-model="fields.brand"
                      :errors="errors"
                      type="text"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Modelo"
                    mode="passive"
                  >
                    <CustomTextInput
                      v-model="fields.model"
                      :model="fields.model"
                      :errors="errors"
                      :placeholder="'Modelo'"
                      type="text"
                    />
                  </ValidationProvider>
                </div>
                <div class="row-bike">
                  <div @click="openYears">
                    <CustomTextInput
                      :isDisabled="true"
                      v-model="fields.year"
                      :placeholder="placeholderYear"
                      type="text"
                      :isDrawer="true"
                    />
                  </div>

                  <ValidationProvider
                    name="Valor da bike"
                    mode="passive"
                    v-slot="{ errors }"
                    :rules="'required'"
                  >
                    <CustomTextInput
                      v-model="fields.amountBike"
                      :model="fields.amountBike"
                      :errors="errors"
                      v-mask="getCurrencyMask(fields.amountBike)"
                      :placeholder="'R$'"
                      type="tel"
                    />
                  </ValidationProvider>
                </div>

                <div @click="openModality">
                  <CustomTextInput
                    :isDisabled="true"
                    v-model="fields.modality"
                    :placeholder="placeholderModality"
                    type="text"
                    :isDrawer="true"
                  />
                </div>
                <div class="mb-5">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="telefone"
                    rules="required|validacao_telefone"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.cellphone"
                      :model="fields.cellphone"
                      :placeholder="'Telefone'"
                      v-mask="['(##) #####-####']"
                      type="tel"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div v-if="selectedTab === 3">
                <ValidationProvider
                  name="número do cartão"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    v-model="fields.card_number"
                    :model="fields.card_number"
                    :placeholder="'Número do Cartão'"
                    v-mask="'#### #### #### ####'"
                    :errors="errors"
                    type="tel"
                  />
                </ValidationProvider>

                <div class="row-plane">
                  <ValidationProvider
                    name="validade"
                    rules="required|min:5|expiracao_cartao"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      v-model="fields.expire_date"
                      :model="fields.expire_date"
                      v-mask="'##/##'"
                      :errors="errors"
                      :placeholder="'Validade'"
                      type="tel"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    name="cvc"
                    rules="required|min:2"
                    v-slot="{ errors }"
                  >
                    <CustomTextInput
                      :errors="errors"
                      v-model="fields.cvv"
                      :model="fields.cvv"
                      v-mask="'###'"
                      :placeholder="'CVC'"
                      type="tel"
                    />
                  </ValidationProvider>
                </div>

                <ValidationProvider
                  name="nome"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <CustomTextInput
                    :errors="errors"
                    v-model="fields.card_name"
                    :model="fields.card_name"
                    :placeholder="'Nome do Cartão'"
                    type="text"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-if="yearly"
                  name="parcela"
                  mode="passive"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <select
                    class="select mt-2"
                    :style="fields.portion && 'color: black;font-weight: 600;'"
                    v-model="fields.portion"
                    :model="fields.portion"
                    :class="classes"
                  >
                    <option
                      v-for="(item, index) in installments"
                      :key="'parcela' + index"
                      :value="item.p"
                    >
                      Parcela {{ index + 1 }}x de {{ item.valor }}
                    </option>
                  </select>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="mt-3">
                  <div class="" v-if="esconderCupom">
                    <b-alert show>
                      Tem cupom de desconto? Valide aqui:
                    </b-alert>
                  </div>
                  <div class="mb-2" v-if="esconderCupom">
                    <ValidationProvider
                      name="cupom"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        :readonly="cupomAplicado"
                        type="text"
                        class="input"
                        :class="classes"
                        v-model="cupom"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="esconderCupom">
                    <b-button
                      v-if="!cupomAplicado"
                      class="continue-btn"
                      variant="success"
                      :disabled="loadingCupom"
                      @click.prevent="onClickAplicarCupom()"
                      >Aplicar</b-button
                    >
                    <b-button
                      v-else
                      class="btn-block continue-btn-d"
                      variant="danger"
                      :disabled="loadingCupom"
                      @click.prevent="removerDesconto()"
                      >Remover</b-button
                    >
                  </div>
                </div>
              </div>
              <button
                id="footerButton"
                class="footer-button-back"
                @click="handlePrevius"
              >
                Voltar
              </button>
              <button
                id="footerButton"
                class="footer-button"
                @click="handleClick"
                :disabled="checkNext(invalid)"
              >
                Avançar
              </button>
            </ValidationObserver>
          </div>
          <div v-if="selectedTab === 3" :key="'step-3'" class="box-info">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                class="info-icon"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <circle cx="12" cy="12" r="9"></circle>
                <line x1="12" y1="8" x2="12" y2="9"></line>
                <line x1="12" y1="11" x2="12" y2="17"></line>
              </svg>
            </div>
            Atenção: O pagamento deve ser feito exclusivamente com cartão de
            crédito.
          </div>
        </div>
      </div>
    </transition>
    <Drawer drag-color="#f6f2fe" :max-height="400" ref="myBottomSheet">
      <template #header>
        <div class="text-drawer">UF</div>

        <div class="box-padding">
          <div class="input-box">
            <svg
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z"
                  fill="#9c9b9c"
                ></path>
              </g>
            </svg>

            <input
              type="text"
              class="input-search"
              v-model="searchQuery"
              placeholder="Consultar"
              @input="handleSearch"
            />
            <svg
              @click="clearQuery"
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.5"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  fill="#9e9e9e"
                ></path>
                <path
                  d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                  fill="#9e9e9e"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <div class="drawer">
        <ul class="list-group">
          <li
            v-for="option in filteredOptions"
            :key="option.id"
            @click="selectUF(option)"
            class="list-drawer"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </Drawer>

    <Drawer drag-color="#f6f2fe" :max-height="400" ref="drawerAno">
      <template #header>
        <div class="text-drawer">Ano de Fabricação</div>

        <div class="box-padding">
          <div class="input-box">
            <svg
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z"
                  fill="#9c9b9c"
                ></path>
              </g>
            </svg>

            <input
              type="text"
              class="input-search"
              v-model="searchQueryAno"
              placeholder="Consultar"
              @input="handleSearchAno"
            />
            <svg
              @click="clearQuery"
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.5"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  fill="#9e9e9e"
                ></path>
                <path
                  d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                  fill="#9e9e9e"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <div class="drawer">
        <ul class="list-group">
          <li
            v-for="option in filteredOptionsYears"
            :key="option.id"
            @click="selectYear(option)"
            class="list-drawer"
          >
            {{ option.AnoBike }}
          </li>
        </ul>
      </div>
    </Drawer>

    <Drawer drag-color="#f6f2fe" :max-height="400" ref="drawerModality">
      <template #header>
        <div class="text-drawer">Modalidade</div>

        <div class="box-padding">
          <div class="input-box">
            <svg
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z"
                  fill="#9c9b9c"
                ></path>
              </g>
            </svg>

            <input
              type="text"
              class="input-search"
              v-model="searchQueryModality"
              placeholder="Consultar"
              @input="handleSearchModality"
            />
            <svg
              @click="clearQuery"
              width="20"
              heigth="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.5"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  fill="#9e9e9e"
                ></path>
                <path
                  d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                  fill="#9e9e9e"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <div class="drawer">
        <ul class="list-group">
          <li
            v-for="option in filteredOptionsModality"
            :key="option.id"
            @click="selectModality(option)"
            class="list-drawer uppercase"
          >
            {{ option.nome }}
          </li>
        </ul>
      </div>
    </Drawer>

    <Drawer drag-color="#f6f2fe" :max-height="400" ref="drawerSocial">
      <div class="social-drawer">
        <p class="social-info-text">Oque é nome social?</p>
        <p class="social-info-desc">
          De acordo com o Decreto nª 8.727/2016. o termo <b>"nome social"</b> se
          refere à disignação pela qual uma pessoa travesti ou transexual se
          identifica e é reconhecida socialmente
        </p>
      </div>
    </Drawer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import CustomTextInput from "@/components/inputs/custom-text-input/CustomTextInput.vue";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import * as D from "@/duck";
export default {
  components: {
    Drawer: VueBottomSheetVue2,
    CustomTextInput: CustomTextInput,
  },
  props: {
    planeSelected: {
      type: Object,
      default: () => ({}),
    },
    planePersonSelected: {
      type: Object,
      default: () => ({}),
    },
    personData: {
      type: Object,
      default: () => ({}),
    },
    bikeData: {
      type: Object,
      default: () => ({}),
    },
    comboData: {
      type: Object,
      default: () => ({}),
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    backStep: {
      type: Function,
      default: () => {},
    },
    previousStep: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cities: D.constants.UFS,
      years: D.constants.YEAR_BIKE_OPTIONS,
      modality: D.constants.MODALITY_OPTIONS,
      selectedTab: 1,
      open: false,
      placeholderUF: "UF",
      placeholderYear: "Ano",
      placeholderModality: "Modalidade",
      hasSocialName: false,
      searchQuery: "",
      searchQueryAno: "",
      searchQueryModality: "",
      selectedUF: "",
      filteredOptionsYears: [],
      filteredOptionsModality: [],
      isVisible: false,
      stepTwoOk: false,
      stepThreeOk: false,
      filteredOptions: [],
      fields: {
        name: "",
        social_name: "",
        email: "",
        cpf: null,
        rg: null,
        value: null,
        amountBike: "",
        cep: null,
        address: "",
        hood: null,
        city: null,
        numberAdress: null,
        number: null,
        card_number: null,
        cvv: null,
        card_name: null,
        expire_date: null,
        cellphone: null,
        uf: "",
        year: null,
        idModality: null,
        modality: null,
        brand: null,
        portion: 1,
        cupom: undefined,
      },
      plane: null,
      personPlane: null,
      exibirCamposEndereco: false,
      loadingCep: false,
      amountCheckout: 0,
      installments: [],
      yearly: false,

      //Cupom
      cupom: undefined,
      loadingCupom: false,
      cupomAplicado: false,
      esconderCupom: true,
      valorCupom: 0,
      cupomAtivo: false,
      valorPlanoAntigo: 0,
      valorPlanoOpcionalAntigo: 0,
    };
  },
  methods: {
    async oterFetureFlagCupom() {
      this.esconderCupom = false;
     
        try {
          this.cupom = undefined;
          this.cupomAplicado = false;
          this.cupomAtivo = false;
          var response = await axios.get(
            `${config.API_URLV2}/cupom/featureFlag`
          );
          this.esconderCupom = response.data;
        } catch {
          this.esconderCupom = false;
        }
    },

    //Cupom
    async onClickAplicarCupom() {
      //Busca apenas se tiver digitado algum cupom
      if (!this.cupom) {
        return;
      }
      try {
        this.loadingCupom = true;
        this.fields.cupom = this.cupom;
        if (this.comboData.planeValue.price > this.valorPlanoAntigo) {
          this.valorPlanoAntigo = this.comboData.planeValue.price;
          this.valorPlanoOpcionalAntigo = this.plane.planeValue.price;
        } else {
          this.comboData.planeValue.price = this.valorPlanoAntigo;
          this.plane.planeValue.price = this.valorPlanoOpcionalAntigo;
        }

        var response = await axios.get(
          `${config.API_URLV2}/cupom/BuscarCupom/${this.cupom}`
        );
        this.valorCupom = response.data.percentual;
        this.cupomAtivo = response.data.ativo;
        const numeroFormatado =
          (this.comboData.planeValue.price * this.valorCupom) / 100;
        this.comboData.planeValue.price =
          this.comboData.planeValue.price -
          parseFloat(numeroFormatado.toFixed(2));

        const numeroFormatadoPlano =
          (this.plane.planeValue.price * this.valorCupom) / 100;

        this.plane.planeValue.price =
          this.plane.planeValue.price -
          parseFloat(numeroFormatadoPlano.toFixed(2));

        this.cupomAplicado = true;
        this.loadingCupom = false;

        if (
          this.plane.planeType === config.ID_TIPO_ASSINATURA_ANUAL ||
          this.plane.planeType === config.ID_TIPO_ASSINATURA_SEMESTRAL
        ) {
          this.amountCheckout =
            this.comboData.planeValue.price + this.plane.planeValue.price;
          await this.searchInstallment();
        }
      } catch (error) {
        this.cupomAplicado = false;
        this.loadingCupom = false;
        this.$toast.error("Cupom de desconto inválido!");
      }
    },

    async removerDesconto() {
      this.fields.cupom = null;
      this.comboData.planeValue.price = this.valorPlanoAntigo;
      this.plane.planeValue.price = this.valorPlanoOpcionalAntigo;
      this.cupom = undefined;
      this.cupomAplicado = false;
      this.cupomAtivo = false;

      if (
        this.plane.planeType === config.ID_TIPO_ASSINATURA_ANUAL ||
        this.plane.planeType === config.ID_TIPO_ASSINATURA_SEMESTRAL
      ) {
        this.amountCheckout =
          this.comboData.planeValue.price + this.plane.planeValue.price;
        await this.searchInstallment();
      }
    },
    async searchInstallment() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/pagamentoSeguro/ObterValorParcela?valorPlano=${this.amountCheckout}`
        );
        this.yearly = true;
        this.installments = response.data.anual;
      } catch (error) {
        this.validacoes(error);
      }
    },
    convertToCents(price) {
      return price.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    },
    selectUF(option) {
      this.fields.uf = option;
      this.placeholderUF = option;
      this.close();
    },
    handlePrevius() {
      if (this.selectedTab > 1) {
        this.selectedTab = this.selectedTab - 1;
      } else {
        this.previousStep();
      }
    },
    validateField(field) {
      return this.$refs.observer.validate(field);
    },
    async validateAllFields() {
      const fields = ["cellphone", "model", "value", "modality", "year"];
      const results = await Promise.all(
        fields.map((field) => this.validateField(field))
      );
      this.stepTwoOk =
        results.every((result) => result === true) &&
        this.fields.year !== null &&
        this.fields.modality !== null;
    },
    async validateAllFieldsCard() {
      const fields = ["card_number", "cvv", "card_name", "expire_date"];
      const results = await Promise.all(
        fields.map((field) => this.validateField(field))
      );
      this.stepThreeOk = results.every((result) => result === true);
    },
    selectYear(option) {
      this.fields.year = option.AnoBike;
      this.placeholderYear = String(option.AnoBike);
      this.closeYears();
    },
    selectModality(option) {
      this.fields.idModality = option.id;
      this.fields.modality = option.name;
      this.placeholderModality = String(option.nome);
      this.closeModality();
    },
    openDrawerSocial() {
      this.$refs.drawerSocial.open();
    },
    closeSocial() {
      this.$refs.drawerSocial.close();
    },
    openzada() {
      this.$refs.myBottomSheet.open();
    },
    close() {
      this.$refs.myBottomSheet.close();
    },
    openYears() {
      this.$refs.drawerAno.open();
    },
    closeYears() {
      this.$refs.drawerAno.close();
    },
    openModality() {
      this.$refs.drawerModality.open();
    },
    closeModality() {
      this.$refs.drawerModality.close();
    },
    clearQuery() {
      this.searchQuery = "";
      this.searchQueryAno = "";
      this.searchQueryModality = "";

      this.handleSearch();
      this.handleSearchAno();
      this.handleSearchModality();
    },
    translateType(type) {
      switch (type) {
        case "ano":
          return "Anual";
        case "mês":
          return "Mensal";
        case "sem":
          return "Semestral";
        default:
          return "";
      }
    },
    checkNext(invalid) {
      if (this.selectedTab === 1) {
        return invalid;
      }
      if (this.selectedTab === 2) {
        this.validateAllFields();
        return !this.stepTwoOk;
      }
      if (this.selectedTab === 3) {
        this.validateAllFieldsCard();
        return !this.stepThreeOk;
      }
      return false;
    },
    handleSearchAno() {
      const query = this.searchQueryAno.toString();
      this.filteredOptionsYears = this.years.filter((option) =>
        option.AnoBike.toString().includes(query)
      );
    },
    handleSearchModality() {
      const query = this.searchQueryModality.toString();
      this.filteredOptionsModality = this.modality.filter((option) =>
        option.nome.toString().includes(query)
      );
    },
    handleSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredOptions = this.cities.filter((option) =>
        option.toLowerCase().includes(query)
      );
    },
    async changeTab() {
      if (this.selectedTab === 1) {
        this.selectedTab = 2;
      } else if (this.selectedTab === 2) {
        this.selectedTab = 3;
      } else if (this.selectedTab === 3) {
        let can = await this.verificarPodeContinuar();
        if (!can) return;
        await this.oterFetureFlagCupom();
        this.emitCkeckoutData();
      }
    },
    async verificarPodeContinuar() {
      try {
        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.fields.email}&cpf=${this.fields.cpf}`
        );

        this.loading = false;

        if (response.data.podeContinuar) return true;

        if (response.data.cliente) {
          Swal.fire({
            title: "Aviso!",
            icon: "info",
            html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
            confirmButtonAriaLabel: "Eu entendi!",
          });
        } else {
          Swal.fire({
            title: "Aviso!",
            icon: "info",
            html: `O CPF informado já está vinculado a uma conta</b>`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
            confirmButtonAriaLabel: "Eu entendi!",
          });
        }
        return false;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possivel prosseguir com o seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    validate() {
      return true;
    },
    isButtonEnabled() {
      return this.fields.name !== "";
    },
    handleClick() {
      this.changeTab();
    },
    getCurrencyMask(value) {
      if (value && value.length <= 6) {
        return "R$ ###";
      } else if (value && value.length <= 8) {
        return "R$ #.###";
      } else if (value && value.length <= 9) {
        return "R$ ##.###";
      } else if (value && value.length <= 11) {
        return "R$ #.###.###";
      } else if (value && value.length <= 12) {
        return "R$ ##.###.###";
      } else {
        return "R$ ###.###.###";
      }
    },
    async initializeFields() {
      if (this.personData) {
        this.fields.name = this.personData.name;
        this.fields.cellphone = this.personData.cellphone;
        this.fields.email = this.personData.email;
        this.placeholderUF = this.personData.uf;
      }
      if (this.planeSelected) {
        this.plane = this.planeSelected;
      }
      if (this.planePersonSelected) {
        this.personPlane = this.planePersonSelected;
      }
      if (this.bikeData) {
        this.fields.brand = this.bikeData.brand;
        this.fields.model = this.bikeData.model;
        this.fields.amountBike = this.bikeData.value;
      }

      if (this.plane.planeValue.type == "ano") {
        const plan = this.comboData?.planeValue?.price ?? 0;
        const safePlan = this.plane?.planeValue?.price ?? 0;
        this.amountCheckout = plan + safePlan;
        await this.searchInstallment();
      }
    },
    emitCkeckoutData() {
      this.$emit("ckeckoutData", this.fields);
    },
    buscarEnderecoCep(cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep?.length < 9) {
        return;
      }

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }
          this.fields.address = response.data.logradouro?.toUpperCase();
          this.fields.city = response.data.localidade?.toUpperCase();
          this.fields.hood = response.data.bairro?.toUpperCase();
          this.selectUF(response.data.uf);

          setTimeout(() => {
            window.scroll({ top: 600, left: 0, behavior: "smooth" });
          }, 300);
        })
        .catch((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;
          this.validacoes(response);
        });
    },
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
    const content = this.$el.querySelector(".bottom-sheet__content");
    if (content) {
      content.style.backgroundColor = "#f6f2fe";
    }
  },
  created() {
    this.filteredOptions = [...this.cities];
    this.filteredOptionsYears = [...this.years];
    this.filteredOptionsModality = [...this.modality];
  },
  watch: {
    "fields.cep": function (value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
    cupom: function (newValue) {
      if (newValue) {
        this.cupom = newValue.toUpperCase().trim();
      }
    },
  },
};
</script>

<style>
.search-address {
  margin-right: 40px;
  font-size: 12px;
  margin-top: 35px;
  text-wrap: nowrap;
}

.header {
  background-color: #f2f2f2;
  padding: 20px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to

/* Início e fim da animação */ {
  transform: translateX(-100%);
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.social-drawer {
  padding: 20px;
}

.social-info-text {
  font-weight: bold;
  font-size: 14px;
  color: #425994;
}

.social-info-desc {
  font-size: 12px;
  color: #465c96;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.tab-content {
  overflow: auto;
  margin-bottom: 40px;
}

.bg-gray {
  background-color: #f4f3f3;
  width: 100%;
  height: 100%;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.header-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.bottom-text {
  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.content {
  padding: 10px;
}

.plan-text {
  color: #838ca3;
}

.change-text {
  font-size: 14px;
  color: #78849e;
  display: flex;
  justify-content: space-between;
}

.social-name {
  font-size: 14px;
  color: #78849e;
  text-decoration: underline;
}

.green-box {
  background-color: #4fa935;
  border-radius: 10px;
  padding: 14px;
}

.white-text {
  color: white;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
}

.row-plane {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.row-bike {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.row-plane-last {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 50px;
}

.row-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #78849e;
  font-weight: bold;
  padding: 8px;
}

.list-drawer {
  text-align: left;
  padding: 8px;
  margin-left: 10px;
  margin-top: 15px;
  list-style-type: none;
}

.text-drawer {
  color: #4fa935;
  padding: 8px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
}

.info-icon {
  color: #919ab0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.box-info {
  padding: 6px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: #e9e9e9;
  border-radius: 10px;
  color: #78849e;
  margin-bottom: 60px;
}

.input-box {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 12px;
  display: flex;
  padding: 8px;
}

.drawer {
  margin-top: 5px;
  display: block;
  padding: 8px;
  width: 100%;
}

.input-search {
  padding: 5px;
  border: none;
  background-color: #e0e0df;
  width: 100%;
  justify-self: center;
  outline: none;
}

.box-padding {
  padding: 8px;
}

.white-box {
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: flex-end;
}

.gray-text {
  color: #838ca3;
  font-size: 14px;
  margin-left: 4px;
  font-weight: bold;
  margin-bottom: 4px;
}

.tabs {
  margin-top: 15px;
  color: #838ca3;
  display: flex;
  font-size: 12px;
  justify-content: space-around;
}

.tab {
  position: relative;
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
}

.tab.selected::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
  font-weight: bold;
  background-color: #838ca3;
}

.switch {
  align-self: flex-end;
  position: relative;
  display: inline-block;
  width: 34px;
  /* Largura do switch */
  height: 20px;
  /* Altura do switch */
  margin: 2px;
  margin-bottom: 20px;
  margin-left: 25px;
}

/* Esconde o input padrão */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Estilo do "slider" ou "knob" do switch */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  /* Fundo branco quando desabilitado */
  transition: 0.4s;
  border: 2px solid black;
  /* Contorno preto */
  border-radius: 20px;
  /* Arredondamento para dar forma oval ao slider */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  /* Altura da knob um pouco menor que a do slider */
  width: 16px;
  /* Largura da knob */
  left: 0px;
  background-color: black;
  /* Cor da knob preta */
  transition: 0.4s;
  border-radius: 50%;
}

/* Muda a cor do fundo do slider e a posição da knob quando o switch está "checked" */
input:checked + .slider {
  background-color: #4caf50;
  /* Verde quando ativado */
}

input:checked + .slider:before {
  transform: translateX(14px);
  /* Move a knob para a direita */
}

/* Efeito opcional para fazer o slider parecer mais "sólido" quando focado */
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.tab.selected .underline {
  display: block;
}
.continue-btn-d {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
